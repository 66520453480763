import {ControllerParams, CreateControllerFn, TFunction} from '@wix/yoshi-flow-editor'
import {
  ExperimentNames,
  GROUPS_APP_DEF_ID,
  GROUPS_SECTION_ID,
  isRtlLanguage,
  PAID_PLANS_APP_DEF_ID,
  PAID_PLANS_SECTION_ID,
  hasSeatingPlan,
} from '@wix/wix-events-commons-statics'
import {ReservationStatus} from '@wix/events-types'
import {createEventHandler} from '@wix/tpa-settings'
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {setInstance, watchInstance} from '../../commons/actions/instance'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isEventsInMembersInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl, isSchedulePageInstalled} from '../../commons/utils/wix-code-api'
import {createUouBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {SiteSettings} from '../../commons/types/state'
import {
  DetailsPageSettingsEventsKeys,
  DetailsSettingsNavigateActions,
  SettingsEvents,
  WarmupStateKey,
} from './Widget/constants'
import {getExtraEventData} from './Widget/actions/event'
import {getEventsMembersPageInfo} from './Widget/actions/events-members-page'
import {addLoginListener, fetchCurrentMember} from './Widget/actions/members'
import {addLocationListener, handleInitialNavigation, navigate} from './Widget/actions/navigation'
import {getMemberRsvp} from './Widget/actions/rsvp'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import {DetailsPageProps} from './Widget/components/app/interfaces'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {defaultInvoiceState} from './Widget/reducers/invoice'
import {getDemoEvent} from './Widget/services/demo-event'
import type {Navigation, SelectedTicket, State, StoreExtraArgs} from './Widget/types'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'
import {parseLocation} from './Widget/utils/navigation'
import {getDraftToken} from './Widget/utils/query'
import {getExportedActions} from './Widget/actions/exported-actions'
import {openSeatingPlan} from './Widget/actions/modals'
import {fillSettingsDefaults} from './defaultParams'
import {PageComponentSettings} from './stylesParams'
import {updateSettings} from './Widget/actions/settings'
import {defaultCheckoutState} from './Widget/reducers/checkout'
import {describeSeatingPlan} from './Widget/actions/seating/plan'
import {shouldNavigateToHeadlessTYP} from './Widget/selectors/navigation'

const createDetailsPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )
  let store = null

  return {
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
    updateAppSettings: async ($w, action) => {
      if (store) {
        setTimeout(() => {
          store.dispatch(updateSettings(action as any))
        }, 100)
      }
    },
    async pageReady() {
      const {
        wixCodeApi,
        appParams: {instance},
      } = controllerParams.controllerConfig
      const {flowAPI} = controllerParams
      const language = getLanguage(wixCodeApi)
      const experiments = flowAPI.experiments.all()
      const serverApi = new Api(controllerParams)
      const controller = controllerParams.controllerConfig
      const pageUrl = await getPageUrl(wixCodeApi)
      const ssr = flowAPI.environment.isSSR

      const state: State = wixCodeApi.window.warmupData.get(WarmupStateKey) ?? undefined

      const getStore = async () => {
        if (ssr || !state) {
          const [initialData, paidPlansEnabled, membersAreaEnabled, groupsInstalled] = await fetchInitialData(
            controllerParams,
            serverApi,
          )

          const newStore = createStore(
            controllerParams,
            {
              ...initialData,
              experiments,
              membersAreaEnabled,
              paidPlansEnabled,
              groups: {installed: groupsInstalled},
            },
            serverApi,
          )

          newStore.dispatch(setBaseEnvironment() as any)

          await newStore.dispatch(fetchCurrentMember() as any)

          await Promise.all([
            newStore.dispatch(handleInitialNavigation() as any),
            newStore.dispatch(getMemberRsvp() as any),
            ...newStore.dispatch(getExtraEventData() as any),
            wixCodeApi.location.query.chooseSeat ? newStore.dispatch(openSeatingPlan() as any) : null,
          ])
          const data = newStore.getState()
          if (flowAPI.experiments.enabled(ExperimentNames.UseWarmupState) && ssr) {
            wixCodeApi.window.warmupData.set(WarmupStateKey, data)
          }
          return newStore
        } else {
          const store = createStore(controllerParams, state, serverApi)
          if (
            flowAPI.experiments.enabled(ExperimentNames.HeadlessPaymentRedirectUrl) &&
            shouldNavigateToHeadlessTYP(state)
          ) {
            store.dispatch(handleInitialNavigation() as any)
          }
          return store
        }
      }

      store = await getStore()
      store.dispatch(setInstance(instance))

      if (flowAPI.experiments.enabled(ExperimentNames.EventDetailsSlots)) {
        const eventId = store.getState().event.id
        const slotAPIFactory = createSlotVeloAPIFactory(controllerParams.controllerConfig)
        for (const slot of ['header', 'map', 'details']) {
          const slot$w = slotAPIFactory.getSlotAPI(slot)
          slot$w.eventId = eventId
          if (slot == 'map') {
            slot$w.resourceId = eventId
          }
        }
      }

      store.dispatch(addLoginListener() as any)
      watchInstance(controller, store.dispatch)
      addLocationListener(controller.wixCodeApi, store)

      const props: DetailsPageProps = {
        state: store.getState(),
        actions: getExportedActions(store),
        isRTL: isRtlLanguage(language),
        pageUrl,
        staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
        // @ts-expect-error
        fitToContentHeight: true,
      }
      controller.setProps(props)
      subscribeToStateChanges(controller, store)
      if (state?.membersAreaEnabled) {
        store.dispatch(getEventsMembersPageInfo() as any)
      }

      componentEventHandler.on(
        DetailsPageSettingsEventsKeys.Navigate,
        (navigateAction: DetailsSettingsNavigateActions) => {
          if (navigateAction === DetailsSettingsNavigateActions.details) {
            store.dispatch(navigate(DETAILS_ROUTE.DETAILS) as any)
          }
          if (navigateAction === DetailsSettingsNavigateActions.form) {
            store.dispatch(navigate(DETAILS_ROUTE.FORM) as any)
          }
        },
      )

      store.dispatch(setInstance(instance))

      if (
        !ssr &&
        flowAPI.experiments.enabled(ExperimentNames.PreloadSeatingSummary) &&
        hasSeatingPlan(store.getState().event)
      ) {
        store.dispatch(describeSeatingPlan())
      }
    },
  }
}

const createStore = (controllerParams: ControllerParams, initialData: any, serverApi: any) => {
  const {controllerConfig: controller} = controllerParams
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = createUouBiMiddlewareWithBiParams(
    {
      wixCodeApi,
      platformAPIs,
      appParams,
      compId,
    },
    eventsUou,
  )

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState},
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      platformAPIs,
      flowAPI: controllerParams.flowAPI,
    },
    middleware: [biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })
}

const getInitialData = async (serverApi: Api, controller: ControllerParams): Promise<Partial<State>> => {
  const {wixCodeApi, config} = controller.controllerConfig
  const flowAPI = controller.flowAPI
  const navigation = parseLocation(wixCodeApi)
  const {slug} = navigation
  const language = getLanguage(wixCodeApi)
  const responsive = isResponsiveEditor(config)
  const {t} = flowAPI.translations

  const newDemo = controller.flowAPI.experiments.enabled(ExperimentNames.WixStudioDemoEvents)

  const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)
  const currentUser = wixCodeApi.user.currentUser

  const {event, component, siteSettings, demoEvents, tickets, demoTickets, schedule, dates, upcomingOccurrencesCount} =
    await serverApi.getData({
      slug,
      responsive,
      schedulePageInstalled,
      draftPreviewToken: getDraftToken(wixCodeApi),
      flowAPI,
      newDemo: newDemo ? true : undefined,
    })

  const invoiceState = await getStateFromInvoice({serverApi, event, navigation})

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsive) : event,
    siteSettings: siteSettings as SiteSettings,
    demoEvents,
    tickets,
    demoTickets,
    schedule,
    upcomingOccurrencesCount,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    component: component && {
      id: component.id,
      settings: fillSettingsDefaults({
        settings: component.config?.settings as PageComponentSettings,
        rtl: isRtlLanguage(language),
        responsive,
        t: t as TFunction,
      }),
    },
    policies: {
      agreed: false,
      policies: [],
      showAlert: false,
    },
    dates,
    currentUser: {
      id: currentUser.id,
      role: currentUser.role,
      loggedIn: currentUser.loggedIn,
    },
    ...invoiceState,
  }
}

const getStateFromInvoice = async ({
  serverApi,
  event,
  navigation,
}: {
  serverApi: Api
  event: wix.events.Event
  navigation: Navigation
}): Promise<Partial<State>> => {
  if (navigation.route === DETAILS_ROUTE.TICKET_FORM && event?.id) {
    const {
      query: {reservationId},
    } = navigation

    if (reservationId && reservationId !== 'undefined') {
      try {
        const invoice = await serverApi.getInvoice(event.id, reservationId)

        if (invoice.reservationStatus === ReservationStatus.RESERVATION_PENDING) {
          const selectedTickets = invoice.reservations.reduce((acc, item) => {
            const priceOptionIds = item.ticketDetails
              .reduce((accumulator, ticketDetails) => {
                for (let i = 0; i < ticketDetails.capacity; i++) {
                  accumulator.push(ticketDetails.pricingOptionId)
                }
                return accumulator
              }, [])
              .filter(a => a)

            return {
              ...acc,
              [item.ticket.id]: {
                quantity: item.quantity,
                donation: item.ticketDetails[0]?.priceOverride,
                pricingOptionIds: priceOptionIds.length ? priceOptionIds : null,
              } as SelectedTicket,
            }
          }, {} as Record<string, SelectedTicket>)

          const ticketsDetailsValidity = invoice.reservations.reduce(
            (acc, ticketReservation) => [...acc, ...Array(ticketReservation.quantity).fill(false)],
            [],
          )
          return {
            invoice: {
              ...defaultInvoiceState,
              invoice: invoice.invoice,
            },
            selectedTickets,
            checkout: {
              ...defaultCheckoutState,
              ticketsDetailsValidity,
            },
            reservation: {
              data: {
                id: reservationId,
                expires: invoice.expires,
              },
              error: null,
            },
          }
        }

        return {}
      } catch (e) {
        return {}
      }
    }
  }

  return {}
}

const fetchInitialData = async (controller: ControllerParams, serverApi: Api) => {
  const {wixCodeApi} = controller.controllerConfig
  const membersV2 = controller.flowAPI.experiments.enabled(ExperimentNames.EventsMembersAreaV2)
  return Promise.all([
    getInitialData(serverApi, controller),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
    isEventsInMembersInstalled(wixCodeApi, membersV2),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: GROUPS_APP_DEF_ID,
      sectionId: GROUPS_SECTION_ID,
    }),
  ])
}

export default createDetailsPageController
